var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"slide-up"}},[(_vm.isActive)?_c('div',{staticClass:"content-container content-half"},[_c('div',{staticClass:"flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-column padding-div w-100"},[_c('div',{staticClass:"fs-32px fw-700 color-dark mb-5"},[_vm._v(" Dados necessários para a inscrição ")]),_c('div',{staticClass:"content-contents"},[_c('v-form',{ref:"form"},[_c('div',{staticClass:"mb-4 mt-5"},[_c('div',{staticClass:"fs-24px fw-500 color-dark"},[_vm._v(" Nome ")]),_c('div',{staticClass:"single-input"},[_c('v-text-field',{staticClass:"input-caps",attrs:{"required":"","rules":[_vm.rules.required, _vm.rules.onlyalpha, _vm.rules.surnamerequired],"placeholder":"Digite sua resposta aqui"},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}})],1)]),_c('div',{staticClass:"mb-4 mt-5"},[_c('div',{staticClass:"fs-24px fw-500 color-dark"},[_vm._v(" E-mail ")]),_c('div',{staticClass:"single-input"},[_c('v-text-field',{attrs:{"required":"","rules":[_vm.rules.required, _vm.rules.email],"placeholder":"Digite sua resposta aqui"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"w-100 padding-right"},[_c('div',{staticClass:"fs-24px fw-500 color-dark"},[_vm._v(" CPF ")]),_c('div',{staticClass:"single-input"},[_c('v-text-field-simplemask',{attrs:{"required":"","rules":[_vm.rules.required, _vm.rules.validcpf],"properties":{
    prefix: '',
    suffix: '',
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: true,
    placeholder: 'Digite sua resposta aqui',
  },"options":{
    inputMask: '###.###.###-##',
    outputMask: '###.###.###-##',
    empty: null,
    applyAfter: false,
    alphanumeric: false,
    lowerCase: false,
  }},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}})],1)]),_c('div',{staticClass:"w-100 padding-left"},[_c('div',{staticClass:"fs-24px fw-500 color-dark"},[_vm._v("Data de nascimento:")]),_c('div',{staticClass:"single-input"},[_c('v-text-field-simplemask',{attrs:{"required":"","rules":[_vm.rules.required, _vm.rules.validdate],"properties":{
    prefix: '',
    suffix: '',
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: true,
    placeholder: 'DD/MM/YYYY',
  },"options":{
    inputMask: '##/##/####',
    outputMask: '##/##/####',
    empty: null,
    applyAfter: false,
    alphanumeric: false,
    lowerCase: false,
  }},model:{value:(_vm.dataNascimento),callback:function ($$v) {_vm.dataNascimento=$$v},expression:"dataNascimento"}})],1)])])])])],1),_c('div',{staticClass:"content-buttons"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"button-primary-bg-primary action-button",attrs:{"id":"iniciar_cadastro"},on:{"click":_vm.next}},[_vm._v(" Avançar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("east")])],1)])])])])]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"popParticipanteBtn",staticClass:"btn fill-after hide",attrs:{"type":"button","data-bs-toggle":"modal","data-bs-target":"#modalParticipante"}},[_vm._v(" popParticipanteBtn ")])]):_vm._e()]),_c('div',{staticClass:"modal fade valia-modal",attrs:{"id":"modalParticipante","data-bs-backdrop":"static","data-bs-keyboard":"false","tabindex":"-1","aria-labelledby":"modalmodalParticipanteLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",staticStyle:{"z-index":"99 !important"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"fs-24px color-primary fw-700 text-center"},[_vm._v("Atenção!")]),_vm._m(0),_c('div',{staticClass:"mt-5 d-flex flex-column align-items-center"},[_c('div',{staticClass:"col-xl-8 col-lg-8 col-md-8 col-sm-12"},[_c('button',{staticClass:"button-primary-bg-primary w-100 modal-button",attrs:{"data-bs-dismiss":"modal"},on:{"click":function($event){return _vm.gotoportal()}}},[_vm._v(" Desejo continuar pela área logada ")]),_c('button',{staticClass:"btn fill-after-w-100 mt-3 w-100 modal-button",attrs:{"type":"button","data-bs-dismiss":"modal"},on:{"click":function($event){return _vm.goahead()}}},[_vm._v(" Continuar a adesão ")])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"fs-18px"},[_vm._v(" Você é um participante Valia, deseja realizar sua adesão pelo portal do participante? "),_c('small',[_vm._v("(será necesário login e senha)")])])])}]

export { render, staticRenderFns }