<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <v-form ref="form">
                <div class="flex-column">
                    <div class="d-flex">
                        <!--<div class="question-number color-white">1-</div>-->
                        <div class="flex-column padding-div w-100">
                            <div class="fs-32px fw-700 color-dark">Informe a empresa</div>
                            <div class="single-input">
                                <v-select placeholder="Selecione" ref="vselect" class="input-caps" required :rules="[rules.required]" v-bind:items="itemsEmpresas" v-on:change="changeVisibility" v-model="valTrabalhaEmpresa" item-value="value" item-text="text"></v-select>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" v-if="valTrabalhaEmpresa == '0'">
                        <!--<div class="question-number">1-</div>-->
                        <div class="flex-column padding-div w-100 mt-0 mb-3">
                            <div class="single-input">
                                <v-text-field required :rules="[rules.requirednome, rules.onlyalpha]" class="input-caps" placeholder="Informe o nome da empresa" v-model="valNomeEmpresa" />
                            </div>
                        </div>

                    </div>
                    <div class="d-flex mt-3 ml-3" v-if="valTrabalhaEmpresa == '0' && fluxo == 'aberturamaior18'">
                        <span class="fs-18px color-gray no-wrap">*A cobrança da contribuição no valor de R${{humanFormat(getPessoa["perfil-plano"]["valor-contribuicao"].replace('.', '').replace(',', '.'))}} para o Plano Prevaler será realizada através de boleto.</span>
                    </div>
                </div>
                <div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button v-on:click="next" class="button-primary-bg-primary action-button">
                            Avançar
                            <v-icon right>east</v-icon>
                        </button>
                    </div>
                    <div class="d-flex justify-content-end display-sm-none">
                        <div class="fs-13px mt-3 display-sm-none">ou aperte <span class="fw-700">Enter</span></div>
                    </div>
                </div>
            </v-form>
        </div>

    </transition>
</template>


<script>
    import 'bootstrap';
    import StepTracker from "../../services/StepTracker";
    import { mapState } from "vuex";
    import Enumerable from "linq";
    export default {
        name: 's01-email',
        props: ['pessoa', 'visible', 'fluxo', 'number'],
        data: () => ({
            options: {
                locale: "pt-BR",
                length: 11,
                precision: 2,
                empty: null,
            },
            isActive: false,
            loading: false,
            selection: false,
            email: "",
            emailConfirm: "",
            hasErrors: false,
            emailRules: [],
            emailRulesConfirmation: [],
            rules: {
            },
            trackMsg: null,
            itemsEmpresas: [
                { text: 'Outros', value: "0" },
            ],
            valTrabalhaEmpresa: null,
            valNomeEmpresa: null,
            valCodigoEmpresa: null,
            showNomeEmpresa: false,
        }),
        watch: {
            visible: function () {
                this.toggle();
            },
        },
        computed: {
            ...mapState(["empresasDesconto"]),
            getPessoa() {
                return this.pessoa
            },
        },
        methods: {
            humanFormat: function (value) {
                if (value || value === 0) {
                    value = Number(value).toLocaleString(this.options.locale, {
                        maximumFractionDigits: this.options.precision,
                        minimumFractionDigits: this.options.precision,
                    });
                } else {
                    value = this.options.empty;
                }
                return value;
            },
            changeVisibility() {
                if (this.valTrabalhaEmpresa == "0")
                    this.valNomeEmpresa = null;
            },
            toggle() {
                this.isActive = !this.isActive;

                if (this.isActive) {
                    this.habilitaOpcoes();
                }

                this.trackMsg = null;

            },
            habilitaOpcoes() {
                if (StepTracker.returnStep(this.number) != null && this.pessoa !== null && this.pessoa.email != null) {
                    var v = this.pessoa.email
                    this.email = v;
                }

                if (StepTracker.returnStep(this.number) == null) {
                    this.itemsEmpresas = [];

                    var ed = Enumerable.From(this.empresasDesconto).OrderBy(e => e.descricaoAbreviada).ToArray();

                    ed.forEach((e) => {
                        this.itemsEmpresas.push({ text: e.descricaoAbreviada, value: e.codigoEmpresa.toString() });
                    });
                    this.itemsEmpresas.push({ text: "Outros", value: "0" });
                }
            },
            next: function (event) {
                event.preventDefault();

                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                    requirednome: value => (!!value && this.valTrabalhaEmpresa == "0") || 'Campo obrigatório.',
                    onlyalpha: value => {
                        const pattern = /^[A-Za-zà-úá-úâ-ûä-üã-õçñÀ-ÙÁ-ÚÂ-ÛÄ-ÜÃ-ÕÇÑ ]+$/
                        return pattern.test(value) || 'Preencha somente com letras, sem caracteres especiais.'
                    },
                };


                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {
                        var val = this.valTrabalhaEmpresa;
                        if (this.valTrabalhaEmpresa == "0") {
                            val = this.valNomeEmpresa;
                            this.pessoa["perfil-pessoal"].codigoEmpresa = null;
                            this.pessoa["perfil-pessoal"].nomeEmpresa = val.capitalize();
                        }
                        else {
                            this.pessoa["perfil-pessoal"].codigoEmpresa = parseInt(this.$refs.vselect.selectedItems[0].value);
                            this.pessoa["perfil-pessoal"].nomeEmpresa = this.$refs.vselect.selectedItems[0].text;
                        }
                        StepTracker.registerStep(this.number, this.trackMsg);
                        this.$emit('nextempresa');
                    }
                });



            },
            back: function () {
                this.$emit('back');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
